/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.footer {
  width: 100%;
  margin: 0 auto;
  background-color: #11252d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.75rem;
}

.footer p {
  width: 20%;
  color: #2fb7e7;
}

.footer .footerSocial {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 25%;
  border-left-style: double;
  border-left-color: #d4e8e1;
  padding: 1.5rem;
}

.footer .footerSocial a {
  width: 30%;
  text-align: center;
  border-style: ridge;
  border-color: #d4e8e1;
  padding: 10px 0px 10px 0px;
  text-decoration: none;
  background-color: #2fb7e7;
  color: #11252d;
}

.footer a {
  text-decoration: none;
  color: #2fb7e7;
}

@media (max-width: 900px) {
  .footer .footerSocial {
    width: 40%;
  }
}

@media (max-width: 600px) {
  .footer .footerSocial {
    width: 50%;
  }
}
