/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  background-color: #125665;
  overflow-x: hidden;
  -webkit-transition: 0.8s;
  -o-transition: 0.8s;
  transition: 0.8s;
  padding-top: 60px;
  border-radius: 10px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  display: block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-align: center;
  border-radius: 5px;
}

.sidenav a:hover {
  color: #f1f1f1;
}
.sidenav a:nth-child(even):hover {
  color: #2fb7cf;
}

.sidenav .navHome {
  color: white;
}
.sidenav .navAbout {
  background-color: #199bb1;
  color: black;
  border-top: 1px solid #d4e8e1;
  border-bottom: 1px solid #d4e8e1;
}
.sidenav .navProject {
  background-color: #d4e8e1;
  color: black;
  border-top: 1px solid #d4e8e1;
  border-bottom: 1px solid #d4e8e1;
}
.sidenav .navCourse {
  background-color: #199bb1;
  color: black;
  border-top: 1px solid #d4e8e1;
  border-bottom: 1px solid #d4e8e1;
}
.sidenav .navBlog {
  background-color: #d4e8e1;
  color: black;
  border-top: 1px solid #d4e8e1;
  border-bottom: 1px solid #d4e8e1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  left: 25px;
  font-size: 36px;
  margin-right: 50px;
}

.closebtn {
  color: lightgrey;
}

.navigation .socialMedia .socialIcons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}

.navigation .socialMedia .socialIcons a img {
  width: 100%;
  height: 100%;
}
.navigation .socialMedia p {
  text-align: center;
  font-size: 1rem;
  color: #d4e8e1;
}

.navigation .socialMedia .resume {
  color: #d4e8e1;
}
.navigation .socialMedia .resume:hover {
  color: black;
}
.hamburgerBar {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 5px;
}
.hamburgerContainer {
  width: 7%;
}

#hamburgerMain {
  overflow: hidden;
  background-color: white;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s; /* Adds a transition effect when the padding is decreased */
  position: fixed; /* Sticky/fixed navbar */
  width: 100%;
  z-index: 98;
}

#topScroll {
  overflow: hidden;
  background-color: white;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s; /* Adds a transition effect when the padding is decreased */
  position: fixed; /* Sticky/fixed navbar */
  width: 3%;
  text-align: center;
  z-index: 98;
  top: 93vh;
  left: 48vw;
  border-radius: 50%;
  opacity: 0.5;
}

#topScroll:hover {
  opacity:1;
}

#topScroll a {
  font-weight: bold;
  font-size: 1.75rem;
  text-decoration: none;
  color: #11252d;
}

@media (max-width: 850px) {

}

@media (max-width: 500px) {
  .sidenav {
    padding-top: 5rem;
  }
  .sidenav a {
    font-size: 0.9rem;
    text-align: left;
    padding-left: 10px;
  }
  .navigation .socialMedia p {
    font-size: 0.75rem;
  }
  .navigation .socialMedia .socialIcons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
  }

  .navigation .socialMedia .socialIcons a img {
    width: 33%;
    height: 100%;
  }

  .hamburgerContainer {
    width: 15%;
  }
  #topScroll {
    width: 7%;
    left: 44vw;
  }
}
